import React from "react"
import ListeGabarit from "./modèles/liste"
import {graphql} from "gatsby";
import styled from "styled-components";

const StyledListeGabarit = styled(ListeGabarit)`
    main {      
        li {
            @media screen and (min-width: 800px) {
                > * {
                    width: 60%;
                }

                > p:first-child {
                    width: 40%;
                    float: left;
                }
            }
        }
    }
`

export const pageQuery = graphql`
    query($id: String!) {
        page: mdx(id: {eq: $id}) {
            ...ListePageContent
        }
    }
`

const LivresGabarit = (props) => <StyledListeGabarit {...props}/>

export default LivresGabarit
